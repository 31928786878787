import React, { useEffect, useState } from 'react'
import { getMediaQuery } from '../../api/media'
import classes from './MediaLibraryPhoto.module.css'
import { BtnIcon } from '../../images/svg'
import { getImage } from '../../utils/getImage'

import { getDatePhoto } from '../../utils/getDate'

export const MediaLibraryPhoto = () => {
  const [media, setMedia] = useState({})
  const [indexSelect, setIndexSelect] = useState(0)
  useEffect(() => {
    ;(async () => {
      try {
        const res = await getMediaQuery({
          type: 'photo',
          'order[dateTime]': 'desc',
        })
        setMedia(res.data)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  const setNextSlide = index => {
    if (index === media.items.length - 1) {
      setIndexSelect(0)
    } else setIndexSelect(index + 1)
  }
  const setPrevSlide = indexSelect => {
    if (indexSelect === 0) {
      setIndexSelect(media.items.length - 1)
    } else setIndexSelect(indexSelect - 1)
  }
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2 className={classes.title}>Фото</h2>
        {media?.items?.map((photo, index) => {
          if (indexSelect === index) {
            return (
              <div className={classes.photo__container}>
                <div className={classes.btn}>
                  <button
                    className={classes.btn__left}
                    onClick={() => setPrevSlide(index)}
                  >
                    <BtnIcon />
                  </button>
                  <img className={classes.photo} src={getImage(photo.cover)} />
                  <button
                    className={classes.btn__right}
                    onClick={() => setNextSlide(index)}
                  >
                    <BtnIcon className={classes.btn__icon} />
                  </button>
                </div>

                <div className={classes.subtitle}>
                  <div className={classes.subtitle__content}>
                    <span className={classes.name}>{photo.name}</span>
                    <span className={classes.date}>
                      {getDatePhoto(photo.dateTime)}
                    </span>
                  </div>
                  <a
                    href={photo.url}
                    target='_blank'
                    className={classes.subtitle__link}
                  >
                    <button className={classes.btn__photo}>
                      <span className={classes.btn__text}>
                        Перейти к альбому
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default MediaLibraryPhoto

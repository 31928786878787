import React from 'react'
import Layout from '@/layouts/MediaLibraryLayout/MediaLibraryLayout'
import { MediaLibraryPhoto } from '../components/MediaLibraryPhoto'
const medialibraryphoto = ({ location }) => {
  return (
    <Layout location={location}>
      <MediaLibraryPhoto />
    </Layout>
  )
}

export default medialibraryphoto
